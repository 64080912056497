<template lang="pug">
  .price-list-item
    ImmutableField(
      title="ota_management.plan_list.form.publish_settings.price_list.car_class"
      :text="item.car_class"
    )
    .row.tooltip-label
      FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.price_list.markup_fees')")
      AppTooltip(
        useAppIcon
        :title="$t('ota_management.company_list.tooltips.auto_publish')"
        icon="exclamationCircleAlt"
      )
      span.required {{ $t('ota_management.required') }}
    .row.mark-up-fee-inputs
      MarkUpFee(
        :updated="item._updated"
        :value="item.markup_fee.value"
        :markup-type="item.markup_fee.type"
        @change-markup-fee="changeMarkupFee"
      )
    .table-row
      FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.basic_rates')")
      VueGoodTable(
        :rows="item.rate_list"
        :columns="columns"
      )
        template(v-slot:table-column="props")
          span(:title="props.column.label") {{ $t(props.column.label) }}
        template(v-slot:table-row="{ column, row }")
          span(v-if="column.field === 'price_label'") {{ priceLabels[row.price_label] }}
    .row.alert-row
      BAlert(
        show
        variant="warning"
      ) {{ $t("ota_management.plan_list.form.publish_settings.price_list.tooltip") }}
    template(v-if="item.basic")
      ImmutableField(
        title="ota_management.plan_list.form.publish_settings.price_list.cancellation_fee"
        :text="item.cancellation_fee"
      )
      ImmutableField(
        title="ota_management.plan_list.form.publish_settings.price_list.standard_option"
        :text="item.standard_option"
      )
      ImmutableField(
        title="ota_management.plan_list.form.publish_settings.price_list.selected_option"
        :text="item.selected_option"
      )
</template>

<script>
  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { split, reduce, map, isNaN } from "lodash-es"
  import { columns } from "./priceTableConfig"
  import "vue-good-table/dist/vue-good-table.css"

  export default {
    components: {
      VueGoodTable,
      MarkUpFee: () => import("./../../CompanyList/MarkUpFee"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      ImmutableField: () => import("./ImmutableField")
    },

    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        columns,
        prefix: ""
      }
    },

    methods: {
      changeMarkupFee(newFee) {
        this.$emit("change-markup-fee", { ...this.item.markup_fee, ...newFee })
      }
    },

    computed: {
      priceLabels() {
        return reduce(
          this.priceKeys,
          (obj, key) => {
            const rightPart = split(key, "_").at(1)
            const number = Number.parseInt(rightPart)
            if (isNaN(number)) {
              obj[key] = this.$t(`company_system.price_periods.${key}`)
            } else {
              obj[key] = this.$t(`company_system.price_periods.${this.slotType}`, { number })
            }
            return obj
          },
          {}
        )
      },

      slotType() {
        return this.item.time_slot_type
      },

      priceKeys() {
        return map(this.item.rate_list, "price_label")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .price-list-item
    padding-top: 20px
    padding-left: 20px
    box-shadow: 2px 2px 4px -1px $default-gray-medium
    border-radius: 5px
    background-color: $default-white
    width: 100%

    .table-row
      overflow-x: scroll
      margin-right: 20px

      ::v-deep
        .vgt-wrap
          width: max-content

        th
          padding-right: 0.75rem
          border-color: $border-element-color !important
          border-width: 2px !important

          &.table-th
            text-align: center
            vertical-align: middle
            color: $default-black !important

          &.price-label
            border-color: $default-white !important
            background-color: $default-white !important
            width: 90px !important

          &.fee
            border-width: 2px
            background-color: $border-element-color !important
            width: 130px !important

        td
          border-color: $border-element-color !important
          border-width: 2px !important

          &.price-label
            text-align: center
            background-color: $border-element-color

          &.fee
            text-align: right

    .row
      margin-bottom: 20px
      padding-left: 15px

      &.alert-row
        margin-top: 10px
        margin-right: 9px

        .alert
          border: none
          background: rgba(249, 215, 28, 0.15)
          text-align: center
          padding: 5px 10px

      &.tooltip-label
        margin-bottom: 10px

      ::v-deep
        span
          &.required
            margin-left: 10px
            color: $default-red

        .label
          color: $default-gray

        .price-label
          background-color: $default-gray-light
</style>
